import { Decimal } from "utils/decimal";

import { Coin } from "hooks";

import { toDecimal, isNumberString, getUsdValue, CoinConfiguration } from ".";

export type CoinConfigType = "min" | "max" | "half" | "double";

const DECIMAL_ZERO = toDecimal("0");

export const getCoinAmountFromConfig = ({
  coinsConfig,
  type,
  betAmount,
  selectedCoin,
  showUSD,
}: {
  coinsConfig: Array<CoinConfiguration>;
  type: CoinConfigType;
  betAmount: string | number;
  selectedCoin: Coin;
  showUSD: boolean;
}): Decimal => {
  if (coinsConfig.length === 0) return DECIMAL_ZERO;

  switch (type) {
    case "max":
    case "min":
      const selectedCoinConfig = coinsConfig.find(
        (coinConfig) => coinConfig.coin === selectedCoin.coin,
      );
      if (!selectedCoinConfig) return DECIMAL_ZERO;
      const configValue =
        selectedCoinConfig[type === "max" ? "maximum_bet" : "minimum_bet"];
      return !showUSD
        ? Decimal.min(toDecimal(configValue), toDecimal(selectedCoin.balance))
        : Decimal.min(
            getUsdValue(selectedCoin, selectedCoin.balance),
            getUsdValue(selectedCoin, configValue),
          );
    case "double":
    case "half":
      if (!isNumberString(String(betAmount))) return DECIMAL_ZERO;
      return toDecimal(betAmount)[type === "half" ? "div" : "mul"](2);
  }
};
